import { w as withSharedProps, _ as __rest, a as __assign } from './withSharedProps-a1728349.js';
import React from 'react';
import { s as styleInject, a as secondaryColorDefaultProps } from './style-inject.es-fc9e633e.js';

var css_248z = "@keyframes spinners-react-diamond{25%{transform:translate(30px,-30px)}50%{transform:translate(60px)}75%{transform:translate(30px,30px)}}";
styleInject(css_248z);

var coords = [
    { x: 3, y: 48 },
    { x: 18, y: 33 },
    { x: 18, y: 48 },
    { x: 18, y: 63 },
    { x: 33, y: 48 },
    // -------------
    { x: 33, y: 18 },
    { x: 33, y: 33 },
    { x: 33, y: 63 },
    { x: 33, y: 78 },
    { x: 48, y: 3 },
    { x: 48, y: 18 },
    { x: 48, y: 33 },
    { x: 48, y: 48 },
    { x: 48, y: 63 },
    { x: 48, y: 78 },
    { x: 48, y: 93 },
    { x: 63, y: 18 },
    { x: 63, y: 33 },
    { x: 63, y: 48 },
    { x: 63, y: 63 },
    { x: 63, y: 78 },
    { x: 78, y: 33 },
    { x: 78, y: 48 },
    { x: 78, y: 63 },
    { x: 93, y: 48 },
];
var Component = function (_a) {
    var secondaryColor = _a.secondaryColor, speed = _a.speed, still = _a.still, thickness = _a.thickness, svgProps = __rest(_a, ["secondaryColor", "speed", "still", "thickness"]);
    var diamondStyle = {
        animation: "spinners-react-diamond " + 140 / speed + "s steps(2, end) infinite",
    };
    if (still)
        diamondStyle.animation = 'none';
    return (React.createElement("svg", __assign({ fill: "currentColor" }, svgProps, { viewBox: "0 0 96 96" }),
        coords.map(function (c) { return (React.createElement("circle", { key: c.x + "-" + c.y, cx: c.x, cy: c.y, fill: secondaryColor, r: 2.5 * (thickness / 100) })); }),
        React.createElement("g", { style: diamondStyle }, coords.filter(function (c, i) { return i < 5; }).map(function (c) { return (React.createElement("circle", { key: "h-" + c.x + "-" + c.y, cx: c.x, cy: c.y, r: 3.5 * (thickness / 100) })); }))));
};
Component.defaultProps = secondaryColorDefaultProps;
var SpinnerRomb = withSharedProps(Component);
var SpinnerDiamond = withSharedProps(Component);

export { SpinnerDiamond, SpinnerRomb };
